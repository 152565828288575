<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import {
   NavigationMenuContent,
   NavigationMenuItem,
   NavigationMenuLink,
   NavigationMenuList,
   NavigationMenuRoot,
   NavigationMenuTrigger,
   NavigationMenuViewport,
} from 'radix-vue'
import { NuxtLink, NuxtLinkLocale, UiExpandable } from '#components'

/**
 * Translations.
 */
const {
   locale,
   locales,
   t,
} = useI18n()

const { name } = useSiteConfig()
/**
 * Get localized route.
 */
const localeRoute = useSwitchLocalePath()

/**
 * Update the locale when the user selects a new one.
 */
async function onUpdate(locale?: LocaleObject) {
   if (!locale) return
   await navigateTo(localeRoute(locale.code))
}

/**
 * List of links.
 */
const links = ['faq', 'guidance-data-entry', 'guidance-data-validation', 'terms-and-conditions']

/**
 * Open state.
 */
const open = ref(false)

/**
 * IUCN tool URL.
 */
const IUCN_TOOL_URL = 'https://www.iucncontributionsfornature.org/entry/'
</script>

<template>
   <NavigationMenuRoot class="relative z-10 flex flex-wrap bg-white">
      <div class="hidden flex-1 md:max-lg:block" />

      <NuxtLinkLocale
         to="/"
         class="flex flex-col justify-center lg:mr-auto"
      >
         <NuxtImg
            src="/logos/iucn_en.png"
            alt="IUCN logo"
            format="webp"
            height="80"
            class="max-h-20 object-contain p-1 xl:ml-12"
         />
         <span class="sr-only">{{ name }}</span>
      </NuxtLinkLocale>

      <div class="flex justify-end max-lg:flex-1 lg:order-2">
         <UiSelect
            v-model="locale"
            :options="locales"
            value-key="code"
            label-key="name"
            :aria-label="t('global.select-language')"
            class="bg-gray-100 p-4 text-link lg:p-8"
            @select="onUpdate"
         >
            <template #prepend>
               <Icon name="heroicons:globe-alt" />
            </template>
         </UiSelect>

         <UiButton
            class="hidden max-lg:block"
            @click="open = !open"
         >
            <Icon name="heroicons-outline:bars-3" />
            <span class="sr-only">{{ t('global.open-menu') }}</span>
         </UiButton>
      </div>

      <UiExpandable
         :open="open"
         class="self-center max-lg:w-full max-md:w-full lg:block lg:overflow-visible"
      >
         <NavigationMenuList class="flex items-center gap-4 p-4 text-center text-sm max-lg:flex-col max-lg:bg-gray-100 md:text-base lg:gap-8 lg:p-8">
            <NavigationMenuItem class="relative hidden lg:block">
               <NavigationMenuTrigger>
                  {{ t('global.resources') }}
                  <Icon name="heroicons-solid:chevron-down" />
               </NavigationMenuTrigger>
               <NavigationMenuContent>
                  <ul class="flex flex-col gap-4 truncate rounded border bg-white p-4 text-left shadow-lg hover:[&_a]:underline">
                     <NavigationMenuLink
                        v-for="link of links"
                        :key="link"
                        as-child
                     >
                        <NuxtLinkLocale :to="{ name: link }">
                           {{ t(`global.${link}`) }}
                        </NuxtLinkLocale>
                     </NavigationMenuLink>
                  </ul>
               </NavigationMenuContent>
               <div class="absolute left-1/2 top-full mt-2 -translate-x-1/2">
                  <NavigationMenuViewport />
               </div>
            </NavigationMenuItem>

            <NavigationMenuItem
               v-for="link of links"
               :key="link"
               class="lg:hidden"
            >
               <NavigationMenuLink as-child>
                  <NuxtLinkLocale :to="{ name: link }">
                     {{ t(`global.${link}`) }}
                  </NuxtLinkLocale>
               </NavigationMenuLink>
            </NavigationMenuItem>

            <NavigationMenuItem>
               <NavigationMenuLink as-child>
                  <NuxtLinkLocale
                     to="/get-started"
                     class="flex items-center gap-2"
                  >
                     <span>{{ t('global.get-started') }}</span>
                     <Icon
                        name="heroicons-solid:question-mark-circle"
                        class="text-xl text-link"
                     />
                  </NuxtLinkLocale>
               </NavigationMenuLink>
            </NavigationMenuItem>

            <NavigationMenuItem>
               <NavigationMenuLink as-child>
                  <UiButton
                     :as="NuxtLink"
                     target="_blank"
                     :to="IUCN_TOOL_URL"
                  >
                     {{ t('global.add-contributions') }}
                  </UiButton>
               </NavigationMenuLink>
            </NavigationMenuItem>
         </NavigationMenuList>
      </UiExpandable>
   </NavigationMenuRoot>
</template>
