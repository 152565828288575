<script setup lang="ts" generic="T extends Record<string, unknown>">
interface Props {
   options: T[]
   valueKey: (keyof T | ((option: T) => string))
   labelKey: (keyof T | ((option: T) => string))
}

const props = defineProps<Props>()

const emit = defineEmits<{
   'update:modelValue': [value: string]
   'select': [option?: T]
}>()

const modelValue = defineModel<string>()

const { class: _class, ...attrs } = useAttrs()

/**
 * Get the value of the option.
 */
function getValue(option: T) {
   if (typeof props.valueKey === 'function') {
      return props.valueKey(option)
   }
   return option[props.valueKey]
}

/**
 * Get the label of the option.
 */
function getLabel(option: T) {
   if (typeof props.labelKey === 'function') {
      return props.labelKey(option)
   }
   return option[props.labelKey]
}

/**
 * Get the option by its value.
 */
function getOption(value: string) {
   return props.options.find(option => getValue(option) === value)
}
</script>

<template>
   <div
      class="flex items-center gap-4"
      :class="_class"
   >
      <slot name="prepend" />
      <select
         v-model="modelValue"
         v-bind="attrs"
         class="appearance-none border-none bg-transparent outline-none"
         @input="emit('select', getOption($event.target.value))"
      >
         <option
            v-for="(option, index) of options"
            :key="index"
            :value="getValue(option)"
         >
            {{ getLabel(option) }}
         </option>
      </select>
      <Icon name="heroicons:chevron-down" />
   </div>
</template>
